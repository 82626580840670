@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Nunito:wght@400;500;600;700;800;900&family=Space+Grotesk:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=League+Spartan&family=Open+Sans:wght@400;700&display=swap");

:root {
  --bg-color-1: #e5e5ef;
  --bg-color-2: linear-gradient(90deg, #e30a01ff0 -5%, #7f070f 100%);
  --bg-color-3: linear-gradient(80deg, #222, #444);
  --body-color: #aaa;
  --skin-color: #e30a01ff0;
  --skin-color2: #7f070f;
  --skin-color3: #474747;
  --text-color: #1d1d1d;
  --text-color2: ##8e8e8e;
  --text-color-alt: #fff;
  --text-color-alt2: #eee;
  --white-color3: #d9d9d9;
  --card-color: linear-gradient(80deg, #121212, #f19333);
  --box-shadow: 0px 1px 8px rgba(24, 24, 24, 0.08);

  --font-light: 300;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 800;

  --bdy-font-family-alt: "League Spartan", sans-serif;
  --bdy-font-family: "Open Sans", sans-serif;

  --font-size-bggr: 3.15rem !important;
  --font-size-bg: 2.5rem !important;
  --font-size-m: 1.5rem !important;
  --font-size-nr: 1rem !important;
  --font-size-sm: 0.875rem !important;
  --font-size-md: 1.2rem !important;
  --font-size-smlr: 0.75rem !important;
  --h1-size: 2.75rem !important;
  --h2-size: 1.75rem !important;
  --h3-size: 1.5rem !important;

  --primary-color: #550273;
  --primary-color-light: #a01ff0;
}

body {
  font-size: var(--font-size-nr) !important;
  font-family: var(--bdy-font-family) !important;
  font-weight: 4o00;
  background: var(--bg-color-1);
  color: var(--primary-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color) !important;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box !important;
  font-size: 14px;
  outline: none;
  transition: all 0.3s ease;
}

::before,
::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--text-color);
}

input,
button {
  font-family: var(--bdy-font-family);
  outline: none;
  border: none;
}

/*-----scroll-bar------*/
::-webkit-scrollbar {
  width: 0.5rem;
  background-color: var(--body-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--skin-color2);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--skin-color);
}

/*-----*scroll-bar-----*/

/*-----Reuseable----*/
.section {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.main-container {
  width: 100%;
  height: 100%;
  background: var(--bg-color-1);
  min-height: 100vh;
  margin: auto;
  overflow-x: hidden;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1040px;
  margin: auto;
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.butn {
  background: var(--skin-color);
  border: 1px solid var(--skin-color);
  color: var(--text-color-alt);
  font-family: var(--bdy-font-family);
  font-size: var(--font-size-sm);
  font-weight: 700;
  padding: 0.5rem 0.5rem;
  text-transform: uppercase;
}

.butn:hover {
  background: transparent;
  border: 1px solid var(--skin-color);
  color: var(--text-color);
}

.Button {
  width: 100%;
  justify-items: center;
  margin: 3rem 0;
}

.btn-alt {
  background: white;
  border: 1px solid var(--text-color2);
  color: var(--text-color);
  font-size: var(--font-size-sm) !important;
  font-weight: 700;
  padding: 0.5rem 0.5rem !important;
  text-transform: uppercase;
}

.btn-container {
  justify-items: center;
}

.btn-alt:hover {
  background: transparent !important;
  border: 1px solid var(--text-color-alt) !important;
  color: var(--text-color-alt) !important;
}

.btn-alt2 {
  background: white;
  border: 1px solid var(--text-color-alt);
  color: var(--text-color);
  font-size: var(--font-size-sm) !important;
  font-weight: 700;
  padding: 0.5rem 0.5rem !important;
  text-transform: uppercase;
  margin: auto;
}

.btn-alt2:hover {
  background: transparent !important;
  border: 1px solid var(--text-color-alt) !important;
  color: var(--text-color-alt) !important;
}

.btns {
  background: var(--bg-color-2) !important;
  border: 1px solid var(--skin-color);
  color: var(--text-color-alt);
  font-size: var(--font-size-sm) !important;
  font-weight: 700;
  border-radius: 20px !important;
  padding: 0.5rem 4rem !important;
  text-transform: uppercase;
}

.btns:hover {
  background: transparent !important;
  border: 1px solid var(--skin-color) !important;
  color: var(--text-color) !important;
}

.title {
  margin-bottom: 2rem;
  gap: 0.5rem;
  text-align: center;
}

.title h3 {
  font-size: var(--h3-size);
  font-weight: 700;
  color: var(--text-color);
  font-family: var(--bdy-font-family);
  text-transform: uppercase;
  text-align: center;
}

.title p {
  font-size: var(--font-size-n);
  font-weight: 500;
  color: var(--text-color2);
  font-family: var(--bdy-font-family);
  text-align: center;
}

.section-title h3 {
  font-size: var(--font-size-n);
  font-weight: 700;
  color: var(--text-color);
}

.section-title p {
  font-size: var(--font-size-sm);
  font-weight: 500;
  color: var(--text-color2);
}

.Container {
  margin: 2rem 0;
  gap: 3rem;
  padding: 1.5rem;
}

.Container2 {
  margin: 4rem 0;
  gap: 3rem;
}

.Container3 {
  margin: 4rem 0;
  gap: 3rem;
  padding: 1.5rem;
}

/*------Breadcrumbs----*/
.section-head {
  padding: 3rem 2rem;
  row-gap: 3rem;
}

.hero-section {
  gap: 1rem;
  margin-bottom: 1rem;
}

.hero-icon {
  padding: 0.5rem;
  background: var(--skin-color);
  border: 1px solid var(--skin-color);
  justify-content: center;
  align-items: center;
  max-width: 2rem;
  color: var(--text-color-alt) !important;
}

.hero-icon:hover {
  background: transparent;
  border: 1px solid var(--skin-color);
  color: var(--text-color) !important;
}

.heroicon {
  font-size: var(--font-size-n);
}

.hero-title {
  font-size: var(--font-size-n);
  font-weight: 600;
  color: var(--text-color2);
  text-align: center;
}

.breadcrumb-container {
  margin: 4rem auto 2rem;
  padding: 2rem 2rem;
  background: #eee;
}

.breadcrumb {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.25rem;
}

.breadcrumbItem {
  padding: 0.35rem 0.65rem;
  background: var(--body-color);
  justify-content: center;
  align-items: center;
  border: 1px solid var(--skin-color);
  color: var(--text-color);
}

.isCurrentPage {
  background: var(--skin-color) !important;
  color: var(--text-color-alt) !important;
}

.breadcrumbLink {
  font-size: var(--font-size-n);
  font-weight: 700;
  text-decoration: none;
}

.seperator {
  height: 0.25rem;
  width: 100%;
  background: var(--body-color);
}

.blur {
  opacity: 0.5;
}

.form-width {
  width: 40%;
  max-width: 60%;
}

.create-payment-container {
  background: white;
  padding: 10px;
  border-radius: 10px;
}

/*------Breadcrumbs----*/

@media (min-width: 780px) {
  .Container {
    padding: 0 5rem;
    max-width: 100%;
    margin: 5rem 0;
  }

  .btns {
    padding: 0.65rem 3rem !important;
  }
}

@media (min-width: 960px) {
  * {
    font-size: 16px;
  }

  .Container3 {
    padding: 0 5rem;
    max-width: 85%;
    margin: 6rem auto;
  }

  .section-header {
    padding: 3rem 0rem 1rem;
    row-gap: 3rem;
  }

  .Container2 {
    padding: 0rem;
    max-width: 100%;
    margin: 6rem auto;
  }

  .breadcrumb-container {
    margin: 4rem auto 2rem;
    padding: 2rem 5rem;
    background: #eee;
  }

  .form-width {
    width: 40%;
    max-width: 60%;
  }
}

*.isNotVerified {
  opacity: 0.4;
  pointer-events: none;
  position: relative;
  cursor: not-allowed;
}

*.isNotVerified::after {
  content: "Your application is in review";
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}

*.isNotVerified.isCard::after {
  top: 0px;
  width: 60%;
}

body {
  background-color: var(--bg-color-1) !important;
}

.login-wrapper-container {
  min-height: 100vh;
  display: flex;
}

.login-col {
  width: 100%;
}

.login-col.right {
  background-image: url("./assets/bg1.jpeg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.atn-btn-primary {
  border: transparent !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  font-family: var(--bdy-font-family-alt) !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
}

.atn-btn {
  display: inline-block !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
  text-align: center !important;
  background-image: none !important;
  line-height: 1.5 !important;
  font-size: 22px !important;
  font-weight: 800 !important;
  font-weight: 800 !important;
  padding: 8px 16px !important;
  height: fit-content !important;
  border-radius: 8px !important;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70%;
  margin: 0 auto;
}

button {
  height: 56px;
  border-radius: 8px;
}

.login-form button {
  width: 100%;
}

input,
select {
  -webkit-appearance: none;
  font-family: var(--bdy-font-family);
  width: 100%;
  border: 1px solid #fff;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;
  outline: 0;
  height: 54px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  color: #00078d;
  -webkit-transition: border-color 0.1s ease-in-out 0s;
  transition: border-color 0.1s ease-in-out 0s;
  overflow: auto;
  border-radius: 16px;
}

.login-form input {
  width: 70%;
  padding-left: 20px;
}

.article-text {
  color: #550273;
  line-height: 1.5;
  padding: 20px 0;
}

.country-code-wrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin: 10px 0px;
  border-right: 1px solid #550273;
  padding-right: 10px;
  cursor: pointer;
}

.login-form-title {
  font-family: var(--bdy-font-family-alt);
  font-size: 32px;
  color: #000;
  font-weight: 800;
}

.login-form-desc {
  color: #550273;
  font-size: 16px;
  line-height: 1.8;
}

a {
  color: #a01ff0 !important;
}

.verify-form input {
  width: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.loan-apply-wrapper .loan-apply-steps {
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: flex;
  background-color: #fff;
  padding: 10px;
  padding-left: 10px;
  width: fit-content;
}

.loan-apply-wrapper .loan-apply-steps li {
  padding-right: 10px;
  cursor: pointer;
  display: flex;
}

.loan-apply-wrapper .step-count {
  width: 26px;
  height: 26px;
  background-color: var(--body-color);
  color: var(--primary-color-light);
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-family: var(--bdy-font-family);
}

.loan-apply-wrapper .loan-apply-steps li.active .step-count {
  background-color: var(--primary-color-light);
  color: #fff;
  font-weight: 800;
}

.league-title {
  font-size: 32px;
  margin-left: 0;
  font-family: var(--bdy-font-family-alt);
  font-weight: 700;
  color: #550273;
}

.box {
  box-shadow: 0 0 0 2pt #a01ff0;
  width: 146px;
  height: 60px;
  border: 2px solid #a01ff0;
  color: #a01ff0;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: var(--bdy-font-family);
  font-weight: 700;
}

.loan-apply-wrapper footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 20px 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.stepper-wrapper {
  display: flex;
  width: 60%;
  max-width: 400px;
  margin: 10px 0px 20px 0;
  cursor: pointer;
}

.stepper-wrapper .step {
  background-color: #fff;
  height: 4px;
  border-radius: 4px;
}

.step {
  flex: 1;
}

.stepper-wrapper .middle-step {
  width: 2px;
}

.ant-slider {
  width: 100% !important;
}

.ant-slider-track {
  background-color: #fff !important;
}

.ant-slider-handle {
  background-color: var(--primary-color-light) !important;
  width: 36px !important;
  height: 36px !important;
  top: -8px !important;
}

.ant-slider-handle:after {
  content: "\2194" !important;
  color: #fff !important;
  font-size: 21px !important;
  padding: 3px 11px;
}



.debit-card-wrapper {
  background-color: #fff;
  padding: 8px 4px;
  margin-right: 8px;
  border-radius: 4px;
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary-box {
  background-color: #fff;
  width: 100%;
  min-height: 40px;
  padding: 10px 16px;
}

.summary-title {
  color: #000 !important;
}

.text-right {
  text-align: right !important;
}

.summary-box h3:last-child {
  margin-bottom: 0 !important;
}

.form-wrapper {
  background-color: #fff;
  width: 50%;
  margin: 0px auto 0 auto;
  padding: 30px 8px;
  border-radius: 8px;
}

.form-input {
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 30px !important;
}

.fs-md {
  font-size: var(--font-size-md) !important;
}

.fs-sm {
  font-size: var(--font-size-smlr) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.selected.ant-card-bordered,
.selected {
  border: 2px solid var(--primary-color-light) !important;
}

.timer-text {
  font-size: 12px;
}

.card-pan {
  margin-right: 16px;
  margin-bottom: 0;
}

.loan-lg-stage {
  display: block;
}

.loan-sm-stage {
  display: none;
}

@media screen and (max-width: 768px) {
  .loan-lg-stage {
    display: none !important;
  }

  .loan-sm-stage {
    display: flex !important;
  }

  .form-wrapper {
    width: 90% !important;
  }

  .form-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  #rc-anchor-container {
    width: 180px !important;
  }
}

.text-right {
  text-align: right;
}

.back-btn {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  cursor: pointer;
}

/* customStyles.css */
.custom-select .ant-select-selector {
  height: 40px;
  /* Adjust the height to your desired value */
  line-height: 40px;
  /* Center the text vertically within the input */
}


.form-header {
  text-align: left;
  font-family: sans-serif;
  border-bottom: 2px solid #f0f0f0;
  padding: 15px;
  background-color: #fafafa;
}

.logo-sm {
  height: 50px;
}

.card-payment {
  display: inline-flex;
}