.dashboard-wrapper {
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  height: 100%;
}

.dashboard-wrapper .dashbarod-lg-menu {
  width: 20%;
  background-color: var(--primary-color);
  z-index: 10;
}

.dashbarod-lg-menu {
  display: flex;
  flex-direction: column;
}

.dashbarod-lg-menu .menu {
  flex: 1;
}

.dashboard-wrapper .main-content {
  flex: 1;
}

.logo-lg {
  margin: 40px 0px 30px 0px;
  display: flex;
  justify-content: center;
}

.lg-menu-wrapper {
  display: flex;
  flex-direction: column;
}

.lg-menu-list,
.logout-menu-wrapper {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.lg-menu-list {
  flex: 1;
}

.lg-menu-list li {
  margin-bottom: 10px;
}

.lg-menu-list li,
.logout-menu-wrapper li {
  color: #fff;
  padding: 10px 0px 10px 16px;
  list-style-type: none;
  cursor: pointer;
}

.lg-menu-list li a {
  color: #fff !important;
  padding-left: 16px;
}

.logout-menu-wrapper li img {
  margin-right: 16px;
}

.lg-menu-list li:has(> a.active-link) {
  background-color: var(--primary-color-light);
}

.menu {}

.logo-lg {
  width: 35px;
  height: 70px;
  text-align: center;
  margin: 20px auto 40px auto;
}

.ant-col-sm-24 {
  width: 100%;
}

.dashboard-mb-menu {
  display: none;
  background-color: var(--primary-color);
  padding: 8px 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.dashboard-mb-menu .logo-lg {
  height: 40px;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .dashbarod-lg-menu {
    display: none;
  }

  .col-xs-6 {
    width: 50% !important;
  }

  .dashboard-mb-menu {
    display: flex;
  }

  .main-content {
    padding-top: 40px;
  }
}

.open {
  display: flex;
}

.closed {
  display: none;
}

.mobile-menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 80%;
  z-index: 12;
  background-color: #550273;
  height: 100%;
  flex-direction: column;
}

.mobile-menu ul.lg-menu-list {}

.mobile-menu ul.lg-menu-list li a:not(.active-line) {
  color: #fff !important;
}